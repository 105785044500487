.mobile-header-button {
    svg {
        width: 25px;
        height: 25px;
    }

    &[ncgmobilemenutrigger] {
        svg-icon-sprite {
            display: block;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
