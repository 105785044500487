$footer-top-background-color: $neutral-light-grey;
$footer-bottom-background-color: $blue-100;

$footer-divider: $grey-40;
$footer-border: 1px solid $footer-divider;

$footer-navbar-item: $blue-100;
$footer-navbar-item-hover: $grabber-100;
$footer-navbar-bottom-item: $white;
$footer-navbar-bottom-item-hover: darken($white, 20%);
$footer-icon-color: $white;
$footer-icon-hover-color: darken($white, 20%);

ncg-footer {
    .footer {
        &__container {
            flex-direction: column;
            max-width: $fullwidth;
            margin: 0 auto;

            @include desktop {
                flex-direction: row;
                padding-right: 3rem;
                padding-left: 3rem;
            }

            @include widescreen {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }

        &-top {
            display: block;
            background-color: $footer-top-background-color;

            @include from($desktop) {
                padding-top: 7rem;
                padding-bottom: 5rem;
            }

            &__columns {
                margin: 0;
                justify-content: space-between;
                gap: 32px;
            }

            &__column {
                padding: 1.6rem 2rem;
                border-bottom: $footer-border;
                flex-grow: 0;
                flex-basis: auto;

                @include from($desktop) {
                    padding: 0;
                    border-bottom: 0;
                }
            }

            &__menu-label {
                flex: 1 0 auto;
                font-family: map-get($font-families, bold);
                font-size: $font-size-display5-min;

                @include from($desktop) {
                    font-size: $font-size-display5-max;
                    padding-bottom: 1.6rem;
                }

                a {
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }

            &__menu-list {
                display: grid;
                gap: 16px;
                padding: 16px 0;

                @include desktop {
                    padding: 0;
                }

                a {
                    padding: 0;
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }
        }

        &-bottom {
            background-color: $footer-bottom-background-color;
            padding-top: 3rem;
            padding-bottom: 6rem;

            @include from($desktop) {
                padding-bottom: 3rem;
            }

            &-grid {
                display: grid;
                grid-template-columns: auto;

                @include from($desktop) {
                    grid-template-columns: auto 1fr auto;
                    align-items: center;
                }
            }

            &__logo {
                @include until($desktop) {
                    order: 1;
                    align-self: center;
                    padding-bottom: 2.5rem;
                    margin: 0 auto;
                }

                @include from($desktop) {
                    padding-right: 9rem;
                }

                svg {
                    width: 87px;
                    height: 49px;
                }
            }

            &__links {
                flex: 1 0 auto;

                @include until($desktop) {
                    order: 3;
                }
            }

            &__navbar {
                flex-wrap: wrap;

                @include until($desktop) {
                    display: flex;
                    flex-flow: wrap row;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: center;

                    .navbar-item {
                        font-size: map-get($font-sizes, x-small);
                    }
                }
            }

            &__item {
                &.navbar-item {
                    color: $footer-navbar-bottom-item;

                    &:hover {
                        color: $footer-navbar-bottom-item-hover;
                    }
                }

                &--divider {
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        height: 60%;
                        width: 1px;
                        background-color: $footer-divider;
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &__social {
                @include until($desktop) {
                    order: 2;
                    padding-bottom: 5rem;
                }

                .footer-bottom {
                    &__item {
                        margin: 0 1rem;

                        @include from($desktop) {
                            margin: 0 1.2rem;
                        }
                    }
                }

                svg {
                    background-color: $footer-icon-color;
                    border-radius: 50%;
                    color: $blue-100;

                    &:hover {
                        background-color: $footer-icon-hover-color;
                    }
                }
            }

            &__copyright {
                font-size: $font-size-body-sm;
                color: $white;
                opacity: 0.5;
                padding-left: 1rem;

                @include until($desktop) {
                    padding-top: 2rem;
                    text-align: center;
                }
            }
        }
    }
}
