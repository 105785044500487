.aside-header {
    &__title {
        font-family: map-get($font-families, bold);
        font-size: $font-size-display2-min;

        @include desktop {
            font-size: $font-size-display2-max;
        }
    }

    &__subtitle {
        font-size: $font-size-display4-min;

        @include desktop {
            font-size: $font-size-display4-max;
        }
    }

    &__side-panel-title {
        font-size: $font-size-display5-min;

        @include desktop {
            font-size: $font-size-display5-max;
        }
    }

    &.is-small {
        &__title {
            font-size: $font-size-display2-min;
        }
    }
}
