.headline-spot {
    text-align: left;

    .column {
        width: 100% !important;
    }

    .is-centered {
        justify-content: flex-start;
    }

    .spot-content {
        &__header--title {
            font-size: $font-size-display2-min;
            font-family: map-get($font-families, bold);

            @include from($desktop) {
                font-size: $font-size-display2-max;
            }
        }

        &__header--subtitle {
            @include touch {
                padding-bottom: $headline-subtitle-padding-touch;
            }

            @include desktop {
                padding-bottom: $headline-subtitle-padding-desktop;
            }
        }

        &__header--title,
        &__header--subtitle {
            @include when-inside('.grid__theme--default') {
                color: $headline-primary-color;
            }

            @include when-inside('.grid__theme--light') {
                color: $headline-primary-color;
            }

            @include when-inside('.grid__theme--dark') {
                color: $headline-primary-dark-color;
            }
        }
    }
}
