.extended-hero-spot {
    &__content {
        gap: 16px;
        justify-content: flex-start;
        text-align: left;

        @include tablet {
            display: flex;
        }
    }

    &__title {
        font-family: map-get($font-families, bold);
        font-size: $font-size-display1-min;
        margin-bottom: 0;

        @include from($desktop) {
            font-size: $font-size-display1-max;
        }
    }

    &__subtitle {
        font-family: map-get($font-families, medium);
        font-size: $font-size-display4-min;
        margin-bottom: 0;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }
    }

    &__cta {
        justify-content: flex-start;
        margin-top: 1rem;
    }
}
