/****** COLORS *******/
$grey-100: black;
$grey-90: #0b0b0b;
$grey-80: #555;
$grey-60: #868686;
$grey-40: #b1b1b3;
$grey-20: #d9d9db;
$grey-10: #e5e5e5;
$grey-05: #f4f4f4;
$grey-00: white;

$neutral-off-black: #0f0f0f;
$neutral-light-grey: #f0f0f0;

$blue-100: #00095b;
$blue-80: #333a7c;
$blue-60: #666b9d;
$blue-40: #999dbd;
$blue-20: #cccede;
$blue-10: #e5e6ee;
$blue-05: #f2f3f7;

$sky-100: #066fef;
$sky-80: #388cf2;
$sky-60: #6aa9f5;
$sky-40: #9bc5f9;
$sky-20: #cde2fc;
$sky-10: #e6f1fd;
$sky-05: #f3f8fe;

$grabber-100: #1700f4;
$grabber-80: #4533f6;
$grabber-60: #7466f8;
$grabber-40: #a299fb;
$grabber-20: #d1ccfd;
$grabber-10: #e8e5fe;
$grabber-05: #f3f2fe;

$twilight-100: #00142e;
$twilight-80: #334358;
$twilight-60: #667282;
$twilight-40: #99a1ab;
$twilight-20: #ccd0d5;
$twilight-10: #e5e7ea;
$twilight-05: #f2f3f5;

$white: $grey-00;
$black: $grey-100;

/****** Text colors ******/
$text: $blue-100;
$text-light: $grey-60;

/****** General colors *******/
$foreground: $white;
$background: $white;
$primary: $blue-100;
$border: $blue-05;
