.model-lead {
    &__title {
        font-family: map-get($font-families, bold);
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }

        @include when-inside('.grid__theme--default') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--light') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--dark') {
            color: $headline-primary-dark-color;
        }
    }
}
