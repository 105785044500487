@font-face {
    font-family: 'FordF1Regular';
    src: url('/assets/fonts/f1/FordF1-Regular.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FordF1Medium';
    src: url('/assets/fonts/f1/FordF1-Medium.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FordF1Bold';
    src:
        local('FordF1Bold'),
        url('/assets/fonts/f1/FordF1-Bold.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
}
