.action-bar {
    &__item {
        background: $grabber-100 !important;

        &--fill {
            color: $white !important;
        }
    }

    &__label {
        color: $white !important;
    }
}
