.tab {
    border-radius: $corner-md;

    &__heading {
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }
    }

    &__image {
        &-img {
            border-radius: $corner-md;
        }
    }
}
