.swiper-button {
    &-disabled {
        display: none;
    }

    &-prev,
    &-next {
        color: $grabber-100;
    }
}
