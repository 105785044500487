.carousel {
    &__arrows-container {
        height: auto;
        width: calc(95% - 8rem);
        position: absolute;
        top: 0;
        left: 50%;
        pointer-events: none;

        @include desktop {
            height: 100%;
            width: 100%;
            left: 0;
        }
    }

    .swiper-button-prev {
        bottom: 50%;
        top: 50%;
        left: 0;
        transform: translateX(-75%);
    }

    .swiper-button-next {
        bottom: 50%;
        top: 50%;
        right: 0;
        transform: translateX(75%);
    }
}
