@import '../../../../../libs/common-styles/src/lib/styles/core/typography';

$font-family-base: 'FordF1Regular', sans-serif;
$font-family-base-medium: 'FordF1Medium', sans-serif;
$font-family-base-bold: 'FordF1Bold', sans-serif;
$font-family-secondary: 'FordF1Regular', sans-serif;
$font-family-secondary-medium: 'FordF1Medium', sans-serif;

// display1
$font-size-display1-max: 64px;
$font-size-display1-min: 40px;
// display2
$font-size-display2-max: 48px;
$font-size-display2-min: 40px;
// display3
$font-size-display3-max: 40px;
$font-size-display3-min: 32px;
// display4
$font-size-display4-max: 32px;
$font-size-display4-min: 24px;
// display5
$font-size-display5-max: 24px;
$font-size-display5-min: 20px;
// body
$font-size-body-lg: 16px;
$font-size-body-md: 14px;
$font-size-body-sm: 12px;

/* COMMON-STYLES OVERRIDES */
$font-size-base: $font-size-body-lg;

/****** Function-specific vars ******/

// CONFIGURATOR
$configurator-headline-size: $font-size-large;
$configurator-headline-weight: $font-weight-base;
$configurator-small-text-weight-bold: $font-weight-bold;
$configurator-label-font-size: $font-size-large;
