/****** COMMOM DEFAULT VARIABLES *******/
@import '../../../../libs/common-styles/src/lib/styles/styles-variables';

/****** FONTS *******/
@import 'fonts/fonts';

/****** VARIABLES *******/
@import '_variables/color-variables';
@import '_variables/corner-variables';
@import '_variables/variables';
@import '_variables/typography';

/****** COMMON LIB *******/
@import '../../../../libs/common-styles/src/lib/styles/styles';

/****** CORE *******/
@import 'core/buttons';
@import 'core/icons';
@import 'core/rte-content';
@import 'core/swiper';
@import 'core/typography';

/****** PAGE *******/
@import 'page/model-page-header';

/****** COMPONENTS *******/
@import 'components/navbar';

/****** COMPONENTS *******/

/****** UI *******/
@import 'ui/action-bar';
@import 'ui/alt-menu-highlight';
@import 'ui/embed';

/****** ELEMENTS *******/
@import 'elements/form';
@import 'elements/info';

/****** FEATURES *******/
@import 'features/aside-header';
@import 'features/footer';
@import 'features/header';
@import 'features/meta-menu';
@import 'features/main-menu';
@import 'features/mega-menu';
@import 'features/mobile-header-search';

/****** SPOTS *******/
@import 'spots/accordion-spot';
@import 'spots/carousel-spot';
@import 'spots/carousel-spot-item';
@import 'spots/cta-spot';
@import 'spots/dual-image-spot';
@import 'spots/extended-hero-spot';
@import 'spots/headline-spot';
@import 'spots/hero-slider-spot';
@import 'spots/hero-spot';
@import 'spots/image-text-picker-spot';
@import 'spots/link-image-spot';
@import 'spots/map-spot';
@import 'spots/model-viewer-lead';
@import 'spots/models-list';
@import 'spots/subnavigation-spot';
@import 'spots/tabs-spot';
@import 'spots/timeline-spot';
@import 'spots/video-spot';
