.models-list {
    &__header--title {
        font-family: map-get($font-families, base);
        font-size: $font-size-display4-min;
        color: $headline-secondary-color;

        @include from($desktop) {
            font-size: $font-size-display5-max;
        }
    }
}
