ncg-alt-menu-highlight {
    a {
        &:hover {
            .highlight-heading,
            .highlight-text {
                color: var(--mega-menu-link-color-hover);
            }
        }
    }

    .highlight-heading {
        font-size: $font-size-display5-min;
        color: var(--mega-menu-link-color);

        @include from($desktop) {
            font-size: $font-size-display5-max;
        }
    }

    .highlight-text.highlight-text {
        color: var(--mega-menu-link-color);
    }
}
