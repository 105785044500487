.is-info {
    font-size: $font-size-body-sm;

    &::before {
        background-image: none;
    }

    @include when-inside('.grid__theme--default') {
        &::before {
            background-color: $blue-100;
            mask-image: url('/assets/icons/info.svg');
        }
    }

    @include when-inside('.grid__theme--light') {
        &::before {
            background-color: $blue-100;
            mask-image: url('/assets/icons/info.svg');
        }
    }
}
