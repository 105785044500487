/* bulma overrides */
.button {
    &.is-primary,
    &.is-filled {
        border-width: 0;
    }

    &.is-arrow {
        padding: $button-padding-vertical $button-arrow-length + $button-arrow-text-space-between $button-padding-vertical 0;
        transition: none;

        &::before {
            content: none;
        }

        &::after {
            left: auto;
            right: $button-arrow-length - $button-arrow-head-size;

            transition: right $button-arrow-duration $button-arrow-easing;
        }

        &:hover {
            padding-left: 0;

            &::after {
                left: auto;
                right: 15px;
            }
        }
    }

    &.is-small:not(.is-rounded) {
        border-radius: $corner-lg;
    }

    &[disabled] {
        opacity: 1;
    }
}
