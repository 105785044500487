.h1,
h1 {
    font-size: $font-size-display1-min;
    font-family: map-get($font-families, bold);

    @include from($desktop) {
        font-size: $font-size-display1-max;
    }
}

.h2,
h2 {
    font-size: $font-size-display2-min;
    font-family: map-get($font-families, bold);

    @include from($desktop) {
        font-size: $font-size-display2-max;
    }
}

.h3,
h3 {
    font-size: $font-size-display3-min;
    font-family: map-get($font-families, bold);

    @include from($desktop) {
        font-size: $font-size-display3-max;
    }
}

.h4,
h4 {
    font-size: $font-size-display4-min;
    font-family: map-get($font-families, bold);

    @include from($desktop) {
        font-size: $font-size-display4-max;
    }
}

.h5,
h5 {
    font-size: $font-size-display5-min;
    font-family: map-get($font-families, bold);

    @include from($desktop) {
        font-size: $font-size-display5-max;
    }
}

b,
strong {
    font-size: inherit;
    color: inherit;
    font-weight: $font-weight-bold;
}
