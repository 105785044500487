.image-text-picker-spot {
    .swiper-slide {
        height: auto;
    }

    .image-text-item {
        &__image,
        &__image::after {
            border-radius: $corner-md;
        }

        &-title {
            font-family: map-get($font-families, bold);
            font-size: $font-size-display4-min;
            margin-bottom: 2rem !important;

            @include from($desktop) {
                font-size: $font-size-display4-max;
            }
        }

        &-text {
            @include when-inside('.grid__theme--default') {
                color: $headline-secondary-color;
            }

            @include when-inside('.grid__theme--light') {
                color: $headline-secondary-color;
            }

            @include when-inside('.grid__theme--dark') {
                color: $headline-secondary-dark-color;
            }
        }
    }

    &--multi__item {
        height: 100%;

        > * {
            height: inherit;
            display: grid;
            grid-template-rows: auto 1fr;
        }

        .image-text-item {
            &__content {
                display: flex;
                flex-direction: column;

                .rte-content {
                    flex: 1;
                }
            }
        }
    }
}
