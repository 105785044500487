.timeline-item {
    &__heading {
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }
    }

    &__image {
        > picture {
            background-color: transparent;
        }

        img {
            border-radius: $corner-md $corner-md 0 0;

            @include desktop {
                border-radius: $corner-md 0 0 $corner-md;
            }
        }
    }

    &__content {
        border-radius: 0 0 $corner-md $corner-md;

        @include desktop {
            border-radius: 0 $corner-md $corner-md;
        }
    }
}
