.rte-content {
    a {
        &:hover:not(.button) {
            text-decoration: underline;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include when-inside('.grid__theme--default') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--light') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--dark') {
            color: $headline-primary-dark-color;
        }
    }

    h3 {
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }
    }

    h4 {
        font-size: $font-size-display5-min;

        @include from($desktop) {
            font-size: $font-size-display5-max;
        }
    }

    h5 {
        font-size: $font-size-base;
    }
}
