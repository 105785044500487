.subnavigation {
    &__header {
        font-size: $font-size-display2-min;
        text-align: left;
        padding: 2rem 0 0 1.5rem;

        @include from($desktop) {
            font-size: $font-size-display2-max;
        }
    }

    &__link--image,
    &__link--image::after {
        border-radius: $corner-md;
    }

    &__item--header {
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }

        @include when-inside('.grid__theme--default') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--light') {
            color: $headline-primary-color;
        }

        @include when-inside('.grid__theme--dark') {
            color: $headline-primary-dark-color;
        }
    }
}
