:root .input,
:root .textarea,
:root .select select {
    border-width: $control-border-width;

    &:focus {
        border-width: 2px;
    }
}

%field-text {
    @include when-inside('.grid__theme--default') {
        color: $input-color;

        &::placeholder {
            color: $blue-60;
        }
    }

    @include when-inside('.grid__theme--light') {
        color: $input-color-light;

        &::placeholder {
            color: $blue-60;
        }
    }

    @include when-inside('.grid__theme--dark') {
        color: $input-color-dark;

        &::placeholder {
            color: $sky-20;
        }
    }
}

%input-bg-color {
    &[disabled]:not(.is-danger) {
        border-color: $input-border-color;
    }

    @include when-inside('.grid__theme--default') {
        &[disabled]:not(.is-danger) {
            border-color: $input-border-color;
        }
    }

    @include when-inside('.grid__theme--light') {
        &[disabled]:not(.is-danger) {
            border-color: $input-border-color-light;
        }
    }

    @include when-inside('.grid__theme--dark') {
        &[disabled]:not(.is-danger) {
            border-color: $input-border-color-dark;
        }
    }
}
