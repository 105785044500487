/* ford custom vars */
$headline-primary-color: $blue-100;
$headline-primary-dark-color: $white;
$headline-secondary-color: $blue-100;
$headline-secondary-dark-color: $white;

/****** BULMA OVERRIDES *******/
$control-border-width: 1px;

/****** LINK TAG  *******/
$link-default-color: $grabber-100;
$link-default-color-hover: $grabber-100;
$link-default-decoration: none;

/****** CUSTOM SCROLL BAR *******/
$scrollbar-thumb-bg: $blue-100;

/****** NAVIGATION *******/
$top-menu-background-color: $neutral-light-grey;
$top-menu-text-color: $blue-100;
$top-menu-border-color: $grey-20;
$mega-menu-link-color: $blue-100;
$mega-menu-link-color-hover: $grabber-100;

$mobile-links-color: $blue-100;

/****** CONFIGURATOR *******/
$configurator-tab-color: $grey-light;
$configurator-tab-color-active: $primary;
$configurator-accent-color: $primary;
$configurator-header-height: 12rem;
$configurator-header-height-mobile: 6rem;

/* grid/_grid */
$default-theme-text: $text;
$light-theme-text: $text;
$dark-theme-text: $white;
$default-theme-bg: $white;
$light-theme-bg: $neutral-light-grey;
$dark-theme-bg: $twilight-100;

/* page/_model-page-header */
$sub-nav-cta-font-weight-key: medium;
/* page/_model-page-nav */
$model-nav-padding-vertical-desktop: 1.9rem;
$model-nav-padding-vertical-fullhd: 1.9rem;
$model-nav-padding-vertical: 0;
$model-page-nav-spot-item-padding-bottom: 3rem;
$model-page-nav-spot-color-hover: $grabber-100;
$model-page-nav-spot-height: 6rem;
$model-page-nav-spot-height-desktop: 8rem;

/* core/_buttons */
$button-border-radius: $corner-lg;
$button-arrow-color: $grabber-100;
$button-border-width: 2px;
$button-filter-color: $blue-100;
$button-filter-color-active: $grabber-100;
// primary
$button-primary-bg: $grabber-100;
$button-primary-color: $white;
$button-primary-border-color: $grabber-100;
$button-primary-hover-bg: $blue-100;
$button-primary-active-bg: $blue-80;
$button-primary-text-hover-color: $white;
$button-primary-border-hover-color: $blue-100;
$button-primary-disabled-bg: $grey-05;
$button-primary-disabled-border-color: $grey-05;
$button-primary-disabled-text-color: $grey-20;
// filled - light
$button-filled-light-bg: $button-primary-bg;
$button-filled-light-border-color: $button-primary-border-color;
$button-filled-light-bg-hover: $button-primary-hover-bg;
$button-filled-light-bg-active: $button-primary-active-bg;
$button-filled-light-border-hover-color: $button-primary-border-hover-color;
$button-filled-light-bg-disabled: $button-primary-disabled-bg;
$button-filled-light-border-disabled-color: $button-primary-disabled-border-color;
$button-filled-light-text-disabled-color: $button-primary-disabled-text-color;
// filled - dark
$button-filled-dark-bg: $white;
$button-filled-dark-text-color: $blue-100;
$button-filled-dark-border-color: $white;
$button-filled-dark-bg-hover: rgba($white, 0.8);
$button-filled-dark-bg-active: rgba($white, 0.4);
$button-filled-dark-text-hover-color: $blue-100;
$button-filled-dark-border-hover-color: rgba($white, 0.8);
$button-filled-dark-bg-disabled: rgba($white, 0.1);
$button-filled-dark-border-disabled-color: rgba($white, 0.1);
$button-filled-dark-text-disabled-color: $blue-40;
// filled - hero
$button-hero-bg: $button-primary-bg;
$button-hero-color: $button-primary-color;
$button-hero-border-color: $button-primary-border-color;
$button-hero-hover-bg: $button-primary-hover-bg;
$button-hero-active-bg: $button-primary-active-bg;
$button-hero-text-hover-color: $button-primary-text-hover-color;
$button-hero-border-hover-color: $button-primary-border-hover-color;
$button-hero-disabled-bg: $button-primary-disabled-bg;
$button-hero-border-disabled-color: $button-primary-disabled-border-color;
$button-hero-text-disabled-color: $button-primary-disabled-text-color;
// filled - extended hero
$button-extended-hero-primary-bg: $button-primary-bg;
$button-extended-hero-primary-color: $button-primary-color;
$button-extended-hero-primary-border-color: $button-primary-border-color;
$button-extended-hero-primary-bg-hover: $button-primary-hover-bg;
$button-extended-hero-primary-bg-active: $button-primary-active-bg;
$button-extended-hero-primary-color-hover: $button-primary-text-hover-color;
$button-extended-hero-primary-border-color-hover: $button-primary-border-hover-color;
$button-extended-hero-primary-bg-disabled: $button-primary-disabled-bg;
$button-extended-hero-primary-border-disabled-color: $button-primary-disabled-border-color;
$button-extended-hero-primary-text-disabled-color: $button-primary-disabled-text-color;
// secondary
$button-secondary-bg: transparent;
$button-secondary-color: $grabber-100;
$button-secondary-border-color: $grabber-100;
$button-secondary-hover-bg: $grabber-20;
$button-secondary-text-hover-color: $grabber-100;
$button-secondary-active-bg: $grabber-40;
$button-secondary-border-hover-color: $grabber-100;
$button-secondary-disabled-bg: transparent;
$button-secondary-disabled-border-color: $grey-20;
$button-secondary-disabled-text-color: $grey-20;
// outline
$button-outline-bg: $button-secondary-bg;
$button-outline-text-color: $button-secondary-color;
$button-outline-border-color: $button-secondary-border-color;
$button-outline-bg-hover: $button-secondary-hover-bg;
$button-outline-bg-active: $button-secondary-active-bg;
$button-outline-text-hover-color: $button-secondary-text-hover-color;
$button-outline-border-hover-color: $button-secondary-border-hover-color;
$button-outline-bg-disabled: $button-secondary-disabled-bg;
$button-outline-border-disabled-color: $button-secondary-disabled-border-color;
$button-outline-text-disabled-color: $button-secondary-disabled-text-color;
// outline - light
$button-outline-light-bg: $button-secondary-bg;
$button-outline-light-text-color: $button-secondary-color;
$button-outline-light-border-color: $button-secondary-border-color;
$button-outline-light-bg-hover: $button-secondary-hover-bg;
$button-outline-light-bg-active: $button-secondary-active-bg;
$button-outline-light-text-hover-color: $button-secondary-text-hover-color;
$button-outline-light-border-hover-color: $button-secondary-border-hover-color;
$button-outline-light-bg-disabled: $button-secondary-disabled-bg;
$button-outline-light-border-disabled-color: $button-secondary-disabled-border-color;
$button-outline-light-text-disabled-color: $button-secondary-disabled-text-color;
// outline - dark
$button-outline-dark-bg: transparent;
$button-outline-dark-text-color: $white;
$button-outline-dark-border-color: $white;
$button-outline-dark-bg-hover: rgba($black, 0.2);
$button-outline-dark-bg-active: rgba($black, 0.4);
$button-outline-dark-text-hover-color: $white;
$button-outline-dark-border-hover-color: $white;
$button-outline-dark-bg-disabled: transparent;
$button-outline-dark-border-disabled-color: rgba($white, 0.1);
$button-outline-dark-text-disabled-color: rgba($white, 0.1);
// outline - extended hero
$button-extended-hero-secondary-bg: $button-outline-dark-bg;
$button-extended-hero-secondary-color: $button-outline-dark-text-color;
$button-extended-hero-secondary-border-color: $button-outline-dark-border-color;
$button-extended-hero-secondary-bg-hover: $button-outline-dark-bg-hover;
$button-extended-hero-secondary-bg-active: $button-outline-dark-bg-active;
$button-extended-hero-secondary-color-hover: $button-outline-dark-text-hover-color;
$button-extended-hero-secondary-border-color-hover: $button-outline-dark-border-hover-color;
$button-extended-hero-secondary-bg-disabled: $button-outline-dark-bg-disabled;
$button-extended-hero-secondary-border-disabled-color: $button-outline-dark-border-disabled-color;
$button-extended-hero-secondary-text-disabled-color: $button-outline-dark-text-disabled-color;
/* core/_rte-content */
$rte-default-theme-text: $blue-100;
$rte-light-theme-text: $blue-100;
$rte-dark-theme-text: $white;
$rte-content-text-color-light: $blue-100;
$rte-content-headline-family-key: bold;

/* elements/_info */
$info-default-color: $blue-100;
$info-light-color: $blue-100;
$info-dark-color: $white;

/* elements/_form */
$input-background-color: transparent;
$input-background-color-light: transparent;
$input-background-color-dark: transparent;
$input-color: $blue-100;
$input-color-light: $blue-100;
$input-color-dark: $white;
$input-border-color: $grabber-100;
$input-border-color-light: $grabber-100;
$input-border-color-dark: $white;
$input-hover-border-color: $grabber-100;
$input-focus-border-color: $grabber-100;
$input-hover-border-color-light: $grabber-100;
$input-focus-border-color-light: $grabber-100;
$input-hover-border-color-dark: $white;
$input-focus-border-color-dark: $white;
$input-radius: $corner-lg;
$checkmark-border-radius: $corner-sm;
$checkmark-border-color: $grabber-100;
$checkmark-checked-color: $white;
$checkmark-checked-background-color: $grabber-100;
$checkmark-top-align: 0;

/* features/_aside-header */
$aside-header-subtitle-color: $headline-secondary-color;
$aside-header-side-panel-color: $headline-secondary-color;
/* features/_breadcrumb */
$breadcrumb-color: $grey-80;
$breadcrumb-border-color: $border;
/* features/_header */
$header-height-desktop: 96px;
$header-height-mobile: 66px;
/* features/_side-menu */
$side-menu-label-color: $blue-100;
$side-menu-label-hover: $grabber-100;
$side-menu-link-active: $grabber-100;
$side-menu-family-key: bold;

/* spots/_accordion-spot */
$accordion-title-default-color: $blue-100;
$accordion-title-light-color: $blue-100;
/* spots/_campaigns-spot */
$campaigns-spot-content-color: $blue-100;
/* spots/_cta-spot */
$cta-link-hover-color: $grabber-100;
/* spots/_map-spot */
$map-phone-title-family-key: secondary;
$map-phone-title-color: $brownish-grey;
