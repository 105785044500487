.model-header {
    &__content {
        @include from($desktop-large) {
            grid-template-columns: auto 1fr 17rem !important;
        }
    }

    &__title h3 {
        font-size: $font-size-display4-min;

        @include from($desktop) {
            font-size: $font-size-display4-max;
        }
    }
}
