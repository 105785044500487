.hero-spot {
    &__media-container {
        border-radius: $corner-lg;
    }

    &__content {
        justify-self: flex-start;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: left;
        padding: 1.9rem 2.5rem 2.1rem;

        @include tablet {
            padding: 4% 0 0 3%;
        }

        @include desktop {
            padding: 7% 0 0 7%;
        }
    }

    .spot-content {
        &__header {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--title {
                font-family: map-get($font-families, bold);
                font-size: $font-size-display1-min;
                margin-bottom: 0;

                @include from($desktop) {
                    font-size: $font-size-display1-max;
                }
            }

            &--subtitle {
                font-family: map-get($font-families, medium);
                font-size: $font-size-display4-min;
                margin-bottom: 0;

                @include from($desktop) {
                    font-size: $font-size-display4-max;
                }
            }
        }
    }

    &__cta {
        margin-top: 1rem;
    }
}
