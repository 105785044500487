ncg-header {
    position: relative;
}

.header {
    position: relative;
    z-index: $index-header;
    background-color: $header-bg-color;
    box-sizing: content-box;

    &__backdrop {
        &::before {
            content: '';
            position: fixed;
            background-color: $black;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        &--is-open {
            &::before {
                opacity: 0.3;
            }
        }
    }

    &--animate {
        transition: transform $image-animation-duration-fast $smooth-in;
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 1fr;
        gap: 32px;
        align-items: center;
        box-sizing: border-box;
        max-width: $fullwidth;
        height: $header-height-mobile;
        padding-right: 1rem;
        padding-left: 1rem;
        margin: 0 auto;

        @media (min-width: 375px) {
            padding-right: 3rem;
            padding-left: 3rem;
        }

        @include desktop {
            grid-template-columns: auto 1fr auto;
            height: $header-height-desktop;

            .logo {
                grid-area: 1;
            }
        }

        @include widescreen {
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }

    &__left,
    &__right {
        display: flex;
        gap: 1rem;
        align-items: flex-end;

        @include desktop {
            align-items: center;
        }
    }

    &__left {
        justify-content: flex-start;
    }

    &__right {
        justify-content: flex-end;
    }
}

.logo {
    color: $primary;

    svg {
        display: block;
        width: 71px;
        height: 40px;

        @include desktop {
            width: 99px;
            height: 56px;
        }
    }
}
